<template>
  <div class="card-item-info mb-2 pt-2">
    <div class="bg-status-item-update mx-2" :class="orderStatusBackground(item.type)">
      <div class="mr-1 dot" :class="orderStatusDot(item.type)"></div>
      <ion-text class="item-status-text" :class="orderStatusColor(item.type)">{{
        orderStatusName(item.type)
      }}</ion-text>
    </div>
    <div class="mx-2 mt-1 fs-13 fw-500 d-flex flex-row align-center">
      <ion-text v-if="isTypeReplaced" class="text-grey middle-line-text">{{ item.product_name }}</ion-text>
      <ion-icon v-if="isTypeReplaced" class="mx-1 icons-15px" color="primary" :icon="arrowForwardOutline" />
      <ion-text>{{ item.product_name }}</ion-text>
    </div>

    <div v-if="item?.is_catch_weight" class="mx-2 d-flex flex-row align-center align-content-center">
      <ion-text class="text-grey fs-13">{{ $t('catch_weight_cap') }}</ion-text>
      <tooltip :isViewOrder="true" />
    </div>
    <div class="grey-lines mt-1"></div>
    <ion-row class="pa-1">
      <ion-col class="pt-1" size="auto">
        <div class="product-image">
          <div class="image-wrapper">
            <td-image
              :image="item?.image"
              :imagePath="IMAGE_PATH.PRODUCT"
              :imageDefault="DEFAULT_PRODUCT_IMAGE"
              :key="item?.image"
            ></td-image>
          </div>
          <div v-if="item.halal" class="halal-label">
            <p class="halal">{{ $t('halal') }}</p>
          </div>
        </div>
      </ion-col>
      <ion-col class="ml-1 pt-2">
        <div class="row fs-13">
          <div class="column">
            <span>
              <span>
                <ion-text class="mr-1">{{ $t('orderB2b.qty') }}:</ion-text>
                <ion-text v-if="showQtyAmends" class="text-grey middle-line-text"
                  >{{ getPreviousValueByName('Quantity', item.info_changed) }}
                </ion-text>
              </span>
              <ion-icon
                v-if="showQtyAmends"
                class="mx-1 icons-15px min-mb"
                color="primary"
                :icon="arrowForwardOutline"
              />
              <ion-text color="primary">
                {{ showQtyAmends ? getNewValueByName('Quantity', item.info_changed) : item.total_qty }}
              </ion-text>
            </span>
            <span>
              <span>
                <ion-text class="mr-1">{{ $t('orderB2b.weight') }}:</ion-text>
                <ion-text v-if="showWeightAmends" class="text-grey middle-line-text">
                  {{ getPreviousValueByName('Weight', item.info_changed) }} KG
                </ion-text>
              </span>
              <ion-icon
                v-if="showWeightAmends"
                class="mx-1 icons-15px min-mb"
                color="primary"
                :icon="arrowForwardOutline"
              />
              <ion-text color="primary"
                >{{ showWeightAmends ? getNewValueByName('Weight', item.info_changed) : item.total_weight }}
                KG
              </ion-text>
            </span>
            <span>
              <ion-text v-if="showPriceAmends" class="text-grey fw-700 middle-line-text">
                {{ userCurrency + getPreviousValueByName('Price', item.info_changed) }}
              </ion-text>
              <ion-icon
                v-if="showPriceAmends"
                class="mx-1 icons-15px min-mb"
                color="primary"
                :icon="arrowForwardOutline"
              />
              <ion-text class="fw-700">
                {{
                  userCurrency +
                  (showPriceAmends || isTypeReplaced
                    ? getNewValueByName('Price', item.info_changed)
                    : item.price_changed)
                }}
              </ion-text>
            </span>
          </div>
        </div>
      </ion-col>
    </ion-row>
    <div v-if="showReason(item)" class="d-flex flex-row mx-1 fs-13">
      <ion-icon class="mx-1 icons-15px min-mb" color="medium" :icon="readerOutline" />
      <ion-text class="mb-1">
        <ion-text class="text-grey mr-1"> {{ $t('additional_info') }}:</ion-text>
        <ion-text v-show="item.reason_type_name"> {{ item.reason_type_name }} </ion-text>
        <ion-text v-show="item.reason_type_name && item.reason">{{ `, ` }}</ion-text>
        <ion-text v-show="item.reason"> {{ item.reason }} </ion-text>
      </ion-text>
    </div>
  </div>
</template>
<script>
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/sale/constants/index';
import { ORDER_AMENDS_STATUS_NAME } from '@/modules/shared/constants';
import { arrowForwardOutline, readerOutline } from 'ionicons/icons';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    Tooltip
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      }
    },
    userCurrency: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { t } = useI18n();
    // cek order status for set background status style
    const orderStatusBackground = (status) => {
      return status === ORDER_AMENDS_STATUS_NAME.NEW
        ? 'bg-green'
        : status === ORDER_AMENDS_STATUS_NAME.DELETED || status === ORDER_AMENDS_STATUS_NAME.DELETE
        ? 'bg-red'
        : 'bg-orange';
    };

    // cek order status for set dot background status style
    const orderStatusDot = (status) => {
      return status === ORDER_AMENDS_STATUS_NAME.NEW
        ? 'green'
        : status === ORDER_AMENDS_STATUS_NAME.DELETED || status === ORDER_AMENDS_STATUS_NAME.DELETE
        ? 'red'
        : 'orange';
    };
    // cek order status for set status title color
    const orderStatusColor = (status) => {
      return status === ORDER_AMENDS_STATUS_NAME.NEW
        ? 'text-green'
        : status === ORDER_AMENDS_STATUS_NAME.DELETED || status === ORDER_AMENDS_STATUS_NAME.DELETE
        ? 'text-red'
        : 'text-orange';
    };

    const orderStatusName = (status) => {
      return status === ORDER_AMENDS_STATUS_NAME.NEW
        ? t('track_order_update_status.new')
        : status === ORDER_AMENDS_STATUS_NAME.DELETED || status === ORDER_AMENDS_STATUS_NAME.DELETE
        ? t('track_order_update_status.removed')
        : status === ORDER_AMENDS_STATUS_NAME.REPLACED || status === ORDER_AMENDS_STATUS_NAME.REPLACE
        ? t('track_order_update_status.replaced')
        : t('track_order_update_status.updated');
    };

    const getNewValueByName = (nameToFind, amendsInfo) => {
      for (const amend of amendsInfo) {
        if (amend.name === nameToFind) {
          return amend.new_value;
        }
      }
      return null; // Return null if the name is not found
    };

    const getPreviousValueByName = (nameToFind, amendsInfo) => {
      for (const amend of amendsInfo) {
        if (amend.name === nameToFind) {
          return amend.previous_value;
        }
      }
      return null; // Return null if the name is not found
    };

    const showQtyAmends = computed(() => {
      return !isTypeReplaced.value && getNewValueByName('Quantity', props.item.info_changed) !== null;
    });

    const showWeightAmends = computed(() => {
      return !isTypeReplaced.value && getNewValueByName('Weight', props.item.info_changed) !== null;
    });

    const showPriceAmends = computed(() => {
      return !isTypeReplaced.value && getNewValueByName('Price', props.item.info_changed) !== null;
    });

    const showReason = (item) => {
      return item.reason_type_name?.length > 0 || item.reason?.length > 0;
    };

    const isTypeReplaced = computed(() => {
      return (
        props.item.type === ORDER_AMENDS_STATUS_NAME.REPLACED ||
        props.item.type === ORDER_AMENDS_STATUS_NAME.REPLACE
      );
    });
    return {
      arrowForwardOutline,
      readerOutline,
      orderStatusBackground,
      orderStatusDot,
      orderStatusColor,
      orderStatusName,
      DEFAULT_PRODUCT_IMAGE,
      IMAGE_PATH,
      getNewValueByName,
      getPreviousValueByName,
      showQtyAmends,
      showWeightAmends,
      showPriceAmends,
      showReason,
      isTypeReplaced
    };
  }
});
</script>

<style lang="scss" scoped>
.text-grey {
  color: #9e9e9e;
}

.middle-line-text {
  text-decoration: line-through;
  text-decoration-line: middle;
}

.move-top {
  margin-top: -4px;
}
.card-item-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--grey-200, #eee);
  background: var(--base-white, #fff);
}

.image-wrapper {
  height: 65px;
  width: 65px;
  border-radius: 4px;
  overflow: hidden;
  object-fit: cover;
}

.product-image::part(image) {
  position: relative;
  height: 65px;
  width: 65px;
  overflow: hidden;
  display: block;
  border-radius: 4px;
}
.halal-label {
  position: absolute;
  margin-top: -12px;
  left: 8;
  width: 65px;
  height: 15px;
  background: #12b76a;
  border-radius: 0px 0px 4px 4px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  box-sizing: border-box;
}
.halal {
  margin-top: 0.2px;
}
ion-img {
  display: block;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
<style src="../style.scss" lang="scss" scoped></style>
